.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/fondo-menu-wild.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -1;
}

.App {
  margin: 0; 
  padding: 0; 
  min-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
@font-face {
  font-family: 'PlayfairDisplay-Italic-VariableFont_wght';
  src: url('./fonts/PlayfairDisplay-Italic-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'LibreBaskerville-Regular';
  src: url('./fonts/LibreBaskerville-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'LibreBaskerville-Italic';
  src: url('./fonts/LibreBaskerville-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'PlayfairDisplay-VariableFont_wght';
  src: url('./fonts/PlayfairDisplay-VariableFont_wght.ttf') format('truetype');
}


h1{
  font-family: 'PlayfairDisplay-Italic-VariableFont_wght';
}
h3{
  font-family: 'PlayfairDisplay-VariableFont_wght';
}

.sections{
   font-family: 'LibreBaskerville-Italic';
}

p{
  font-family: 'LibreBaskerville-Regular';
}
i{
  font-family: 'LibreBaskerville-Italic';
}

.food{
  margin-top: 35px;
}

@media(width <= 450px){
  .content{
    text-align: center;
    max-width: 350px;
    margin: auto;
  }

  .food p{
    margin-bottom: 10px;
  }
  .morning-boosters-extras{
    border: 2px solid black;
    padding-top:10px ;
    margin-top: 50px;
    box-shadow: 
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5),
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5);
  }

  .morning-boosters-extras h3, p{
    margin: 0;
  }


  img{
    max-width: 350px;
    max-height: 450px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  .close-button{
    margin-top: 10px;
    max-height: 30px;
    width: auto;
 }

}

@media (min-width: 451px) and (max-width: 600px) {
  .content {
    align-items: center;
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }

  .morning-boosters-extras {
    max-width: 600px;
    margin:auto;
    border: 2px solid black;
    padding-top: 15px;
    margin-top: 25px;
    box-shadow: 
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5);
  }

  .morning-boosters-extras h3, p {
    margin: 10px 0;
  }

  img {
    max-width: 550px;
    max-height: 550px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 15px;
  }

  .close-button {
    margin-top: 15px;
    max-height: 40px;
    width: auto;
  }
}



@media (min-width: 601px) and (max-width: 1024px) {
  .content {
    align-items: center;
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }

  .morning-boosters-extras {
    max-width: 600px;
    margin:auto;
    border: 2px solid black;
    padding-top: 15px;
    margin-top: 25px;
    box-shadow: 
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5);
  }

  .morning-boosters-extras h3, p {
    margin: 10px 0;
  }

  img {
    max-width: 600px;
    max-height: 600px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .close-button {
    margin-top: 15px;
    max-height: 40px;
    width: auto;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .content {
    align-items: center;
    text-align: center;
    max-width: 1000px;
    margin: auto;
    padding: 20px;
  }

  .morning-boosters-extras {
    max-width: 600px;
    margin:auto;
    border: 2px solid black;
    padding-top: 15px;
    margin-top: 25px;
    box-shadow: 
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5);
  }

  .morning-boosters-extras h3, p {
    margin: 10px 0;
  }

  img {
    max-width: 1000px;
    max-height: 1000px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .close-button {
    margin-top: 15px;
    max-height: 40px;
    width: auto;
  }
}

@media(width >= 1367px){
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:#f5f5dc;
    background-size: 20%, 20%;
    background-position: left, right;
    background-repeat: no-repeat;
    padding: 0;
}

.content {
    text-align: center;
    max-width: 650px;
    min-height: 100vh;
    margin: 0 auto;
    background-image: url('../public/fondo-menu-wild.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    padding: 20px;
    box-sizing: border-box;
}


  .morning-boosters-extras{
    border: 2px solid black;
    padding-top:10px ;
    margin-top: 20px;
    box-shadow: 
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5),
      5px 0px 0px rgba(146, 146, 146, 0.5),
      0px -5px 0px rgba(146, 146, 146, 0.5);
  }

  .morning-boosters-extras h3, p{
    margin: 0;
  }


  img{
    max-width: 900px;
    max-height: 900px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 25px;
  }

  .close-button{
    margin-top: 10px;
    max-height: 30px;
    width: auto;
 }

}


